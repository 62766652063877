/* eslint-disable @typescript-eslint/ban-ts-comment */
import { buildConfig } from '@config/build-config';
import { LocaleEnum } from '@wizbii/models';

const DEFAULT_VARS = {
  API_PLATFORM: 'staging',
  API_DOMAIN: 'staging.k8s.wizbii.us',
  ALGOLIA_APP_ID: 'testing1G17FKGF4S',
  ALGOLIA_API_KEY: '097ca7bbd7c3e8a0a4a3104d90aa0aef',
  WIZBII_FILES_GCS_BUCKET: 'wizbii-files-qa3',
};

// Read static environment variables (provided at build time with custom Webpack config)
const useAnsibleVars = !!process.env.USE_ANSIBLE_VARS;
// @ts-ignore
const isProdBuild = buildConfig === 'production';
const version = process.env.VERSION;

// Retrieve Ansible environment variables (injected during SSR), if any
let ansibleVars: Record<string, string> = {};
if (typeof window === 'object') {
  ansibleVars = window['env'] || {};
} else if (typeof global === 'object') {
  ansibleVars = global['ANGULAR_ENV'] || {};
}

// Determine deployment platform and domain from Ansible vars
const platform = ansibleVars.PLATFORM || 'local';
const domain = ansibleVars.WIZBII_DOMAIN;
const isDeployed = !!ansibleVars.PLATFORM;
const locale = LocaleEnum.fr_FR;

// Determine runtime environment variables
// => use Ansible vars if `USE_ANSIBLE_VARS` is set at build time (for prod and automatic QA deploys)
// => default to QA3 vars (for local dev and manual QA deploys)
const runtimeEnv = useAnsibleVars ? ansibleVars : DEFAULT_VARS;

// Determine API platform and domain from runtime vars
const apiPlatform = runtimeEnv.API_PLATFORM;
const apiDomain = runtimeEnv.API_DOMAIN;
const admiin = `https://admiin.${apiDomain}`;

export const environment = {
  applicationId: 'admiin',
  platform,
  domain,
  isDeployed,
  apiPlatform,
  apiDomain,
  isProdBuild,
  version,
  locale,
  bugsnagId: '4319ac7e39cd229de1548e5ecf41594e',
  host: {
    account: 'https://account.api.%host%',
  },
  api: {
    account: `https://account.api.${apiDomain}`,
    authentication: `https://auth.${apiDomain}`,
    company: `https://company-api.${apiDomain}`,
    email: `https://email.api.${apiDomain}`,
    file: `https://file.${apiDomain}`,
    googleStorage: 'https://storage.googleapis.com',
    imaginary: `https://imaginary.${apiDomain}`,
    monitor: `https://monitor.api.${apiDomain}`,
    place: `https://place.${apiDomain}`,
    permii: `https://permii.api.${apiDomain}`,
    fibii: `https://fibii.api.${apiDomain}`,
    profile: `https://profile-api.${apiDomain}`,
    sso: `https://sso.internal.${apiDomain}`,
    subscription: `https://subscription.api.${apiDomain}`,
    recruiter: `https://recruiter.api.qa3.ats-wizbii.us`,
    job: `https://job-api.${apiDomain}`,
    school: `https://school-api.${apiDomain}`,
    abtest: `https://abtest-api.${apiDomain}`,
  },
  urls: {
    admiin,
    ssoRedirectUrl: `${
      platform !== 'local' ? admiin : 'https://localhost.0' // sso-internal and Auth API have strict domain rules
    }`,
    drive: `https://app.drive.${apiDomain}`,
    account: `https://account.${apiDomain}`,
    job: `https://www.${apiDomain}`,
    pro: `https://pro.${apiDomain}`,
  },
  algolia: {
    applicationId: runtimeEnv.ALGOLIA_APP_ID,
    apiKey: runtimeEnv.ALGOLIA_API_KEY,
    index: {
      members: `${apiPlatform}_members`,
      companies: `${apiPlatform}_companies`,
      recruiters: `${apiPlatform}_recruiters`,
      jobs: `${apiPlatform}_jobs`,
    },
  },
  wizbiiFiles: runtimeEnv.WIZBII_FILES_GCS_BUCKET,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
if (!isProdBuild) {
  import('zone.js/dist/zone-error'); // Included with Angular CLI.
}
