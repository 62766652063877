export interface Role {
  role: string;
  name: string;
  description: string;
}

export interface RoleFormItem {
  role: string;
  name: string;
  description: string;
  controlName: string;
  isGranted: boolean;
}

export const RoleTypes = {
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_CRM_MANAGER: 'ROLE_CRM_MANAGER',
  ROLE_DRIVE_ADMIN: 'ROLE_DRIVE_ADMIN',
  ROLE_DROPITO: 'ROLE_DROPITO',
  ROLE_SOURCING: 'ROLE_SOURCING',
  ROLE_OPS_SOURCING_MANAGER: 'ROLE_OPS_SOURCING_MANAGER',
  ROLE_OPS_PROJECT_MANAGER: 'ROLE_OPS_PROJECT_MANAGER',
  ROLE_OPS_1J1S: 'ROLE_OPS_1J1S',
};
