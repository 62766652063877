import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngxs/store';
import { JwtDelete, JwtSet } from '@stores/jwt/jwt.action';
import { JwtState } from '@stores/jwt/jwt.state';
import { JwtServiceInterface, JwtTokens } from '@wizbii/jwt';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class JwtService implements JwtServiceInterface {
  blacklistRoutes: (string | RegExp)[];

  constructor(private readonly store: Store, private readonly authService: AuthenticationWebservice) {
    this.blacklistRoutes = [
      'https://storage.googleapis.com',
      new RegExp(
        `https://auth.${environment.apiDomain}(?!(/v1/(user|auth)).*(/email|password|overview|login-token|recovery-token|authentication))`,
        'i'
      ),
    ];
  }

  getTokens(): Observable<JwtTokens> {
    return this.store.selectOnce(JwtState);
  }

  logout(): void {
    this.store.dispatch(new JwtDelete()).pipe(tap(() => window.location.reload()));
  }

  refreshToken(tokens: JwtTokens): Observable<JwtTokens> {
    return this.authService
      .refreshToken(tokens)
      .pipe(switchMap((newTokens) => this.store.dispatch(new JwtSet(newTokens)).pipe(map(() => newTokens))));
  }

  saveLocalTokens(jwtTokens: JwtTokens): void {
    this.store.dispatch(new JwtSet(jwtTokens));
  }
}
