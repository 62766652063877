/* eslint-disable no-useless-escape */
import { CommonModule, ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreComponent } from '@core/components/core/core.component';
import { SpriteComponent } from '@core/components/sprite/sprite.component';
import { ngxsConfig } from '@core/ngxs.config';
import { AppBrowserViewportScroller } from '@core/scroller/app-browser-viewport-scroller';
import { WizbiiRouterStateSerializer } from '@core/serializer/router.serializer';
import { JwtService } from '@core/services/jwt/jwt.service';
import { environment } from '@environment';
import { FeaturesModule } from '@features/features.module';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { JwtState } from '@stores/jwt/jwt.state';
import { AlgoliaCredentialsService } from '@webservices/algolia/algolia-credentials.service';
import { ALGOLIA_CREDENTIALS_FETCHERS_TOKEN, Credentials, CredentialsFetchers } from '@wizbii/algolia';
import { MUST_BE_AUTHORIZED_GUARD_CONFIG, PageUnauthorizedModule } from '@wizbii/angular-backoffice-ui';
import { bugsnagErrorHandlerFactory, BugsnagGroupingRule } from '@wizbii/angular-bugsnag';
import { NotificationsModule } from '@wizbii/angular-ui';
import { DataStorageService, ImageService } from '@wizbii/angular-utilities';
import { JWT_SERVICE_TOKEN, JwtInterceptor } from '@wizbii/jwt';
import { CookieService } from 'ngx-cookie-service';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { Observable } from 'rxjs';

const MESSAGE_GROUPS: BugsnagGroupingRule[] = [
  { regex: /(Loading chunk) [0-9]+ (failed)[^]*/, msg: '$1 $2' },
  { regex: /(Uncaught \(in promise\): e: {)[^]*(\"status\":[0-9]{1,3},\"statusText\":\"[^"]+\")[^]*$/, msg: '$1$2}' },
  { regex: /(\/profiles)\/[^/]+(.*):/, msg: '$1/<profile-id>$2:' },
  { regex: /(\/user)\/[^/]+(.*):/, msg: '$1/<user-id>$2:' },
  { regex: /(\/company)\/[^/]+(.*):/, msg: '$1/<company-id>$2:' },
  { regex: /(\/jobs)\/[^/]+(.*):/, msg: '$1/<job-id>$2:' },
  { regex: /(\/schools)\/[^/]+(.*):/, msg: '$1/<school-id>$2:' },
  { regex: /(\/location\?name=)[^:]+/, msg: '$1<keyword>' },
  { regex: /(\/location)\/[^:]+/, msg: '$1/<location-id>' },
  { regex: /(\/wizbii-files)\/[^:]+/, msg: '$1/<file-name>' },
  { regex: /(\/m-api.wizbii.com\/v1)\/[^:]+/, msg: '$1/<media-endpoint>' },
  { regex: /(animation trigger ".+" has failed to build)[^]*/, msg: '$1' },
  { regex: /(JSONP request)[^]*(failed|timed out)$/, msg: '$1 $2' },
];

@NgModule({
  imports: [QuicklinkModule],
  exports: [QuicklinkModule],
  declarations: [],
})
export class QuicklinkSharedModule {}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    QuicklinkSharedModule,
    RouterModule.forRoot([], {
      initialNavigation: 'enabledNonBlocking',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
      relativeLinkResolution: 'legacy',
    }),
    NgxsModule.forRoot([JwtState], ngxsConfig),
    NgxsRouterPluginModule.forRoot(),
    ScrollToModule.forRoot(),
    HttpClientModule,

    MatProgressBarModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    PageUnauthorizedModule,
    NotificationsModule.forRoot(),

    FeaturesModule,

    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'Wizbii Admin v3', maxAge: 30 }),
  ],
  providers: [
    DataStorageService,
    CookieService,
    {
      provide: ErrorHandler,
      useFactory: bugsnagErrorHandlerFactory({
        apiKey: environment.bugsnagId,
        appVersion: environment.version,
        autoTrackSessions: false,
        releaseStage: environment.platform,
        isDeployed: environment.isDeployed,
        groupingRules: MESSAGE_GROUPS,
      }),
    },

    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },

    { provide: ViewportScroller, useClass: AppBrowserViewportScroller },

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: JWT_SERVICE_TOKEN, useClass: JwtService },

    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },

    { provide: ImageService, useValue: new ImageService(environment.api.imaginary) },

    {
      provide: ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
      useFactory: (algoliaCredentialsService: AlgoliaCredentialsService): CredentialsFetchers => {
        const credentialsObservableFromAccount = algoliaCredentialsService.getCredentialsFromAccount();

        return {
          members: {
            fetchCredentials(): Observable<Credentials> {
              return credentialsObservableFromAccount;
            },
          },
        };
      },
      deps: [AlgoliaCredentialsService],
    },
    {
      provide: MUST_BE_AUTHORIZED_GUARD_CONFIG,
      useFactory: (store: Store) => ({
        listRoles$: store.select(JwtState.roles),
        listAuthorizedRoles: ['ROLE_ADMIN'],
        platform: environment.platform,
      }),
      deps: [Store],
    },
  ],
  declarations: [CoreComponent, SpriteComponent],
  bootstrap: [CoreComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
