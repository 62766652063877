import { Injectable } from '@angular/core';
import { RoleTypes } from '@models/commons/role';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { JwtDelete, JwtSet } from '@stores/jwt/jwt.action';
import { deserializeJwt, JwtTokens } from '@wizbii/jwt';
import { JwtDeserialized } from '@wizbii/jwt/lib/jwt-utils';
import { CookieService } from 'ngx-cookie-service';

export type JwtStateModel = JwtTokens;
export const JwtStateToken = new StateToken<JwtStateModel>('jwt');

@State({
  name: JwtStateToken,
  defaults: null,
})
@Injectable()
export class JwtState implements NgxsOnInit {
  static readonly TOKEN_KEY = 'wizbii_bo';
  static readonly EXPIRY_KEY = 'wizbii_bo_expiry';

  @Selector([JwtStateToken])
  static info(state: JwtStateModel): JwtDeserialized | null {
    return state?.token ? deserializeJwt(state.token) : null;
  }

  @Selector([JwtStateToken])
  static isLogged(state: JwtStateModel): boolean {
    return !!state;
  }

  @Selector([JwtStateToken])
  static userId(state: JwtStateModel): string {
    return state?.token ? deserializeJwt(state.token)['user-id'] : null;
  }

  @Selector([JwtStateToken])
  static isSuperAdmin(state: JwtStateModel): boolean {
    return state?.token ? deserializeJwt(state.token)['roles'].includes(RoleTypes.ROLE_SUPER_ADMIN) : false;
  }

  @Selector([JwtStateToken])
  static isAdmin(state: JwtStateModel): boolean {
    return state?.token ? deserializeJwt(state.token)['roles'].includes(RoleTypes.ROLE_ADMIN) : false;
  }

  @Selector([JwtStateToken])
  static roles(state: JwtStateModel): string[] | null {
    return state?.token ? deserializeJwt(state.token)['roles'] : null;
  }

  constructor(private readonly cookieService: CookieService) {}

  ngxsOnInit(ctx?: StateContext<any>): any {
    ctx.setState(this.readTokens());
  }

  @Action(JwtSet)
  jwtSet(ctx: StateContext<JwtStateModel>, action: JwtSet): JwtTokens {
    this.writeTokens(action.jwtTokens);
    return ctx.setState(action.jwtTokens);
  }

  @Action(JwtDelete)
  jwtDelete(ctx: StateContext<JwtStateModel>): JwtTokens {
    this.forgetTokens();
    return ctx.setState(null);
  }

  private readTokens(): JwtTokens | null {
    const rawTokens = JSON.parse(this.cookieService.get(JwtState.TOKEN_KEY) || 'null');
    return rawTokens ? rawTokens : null;
  }

  private writeTokens(tokens: JwtTokens): void {
    const cookieDomain = this.getCookieDomain();
    const expiryExists = this.cookieService.check(JwtState.EXPIRY_KEY);
    const msIn390Days = 1000 * 3600 * 24 * 390;
    const expiry = expiryExists
      ? new Date(this.cookieService.get(JwtState.EXPIRY_KEY))
      : new Date(Date.now() + msIn390Days);

    if (!expiryExists) {
      this.cookieService.set(
        JwtState.EXPIRY_KEY,
        expiry.getTime().toString(),
        expiry,
        '/',
        cookieDomain,
        cookieDomain !== 'localhost',
        cookieDomain === 'localhost' ? 'Lax' : 'None'
      );
    }

    this.cookieService.set(
      JwtState.TOKEN_KEY,
      JSON.stringify(tokens),
      expiry,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private forgetTokens(): void {
    const cookieDomain = this.getCookieDomain();
    const forgetDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');

    this.cookieService.set(
      JwtState.TOKEN_KEY,
      '',
      forgetDate,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );

    this.cookieService.set(
      JwtState.EXPIRY_KEY,
      '',
      forgetDate,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
  }
}
