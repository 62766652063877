import { JwtTokens } from '@wizbii/jwt';

export class JwtSet {
  static readonly type = '[Jwt] Set';
  constructor(public jwtTokens: JwtTokens) {}
}

export class JwtDelete {
  static readonly type = '[Jwt] Delete';
}
