import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Credentials } from '@wizbii/algolia';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlgoliaCredentialsService {
  constructor(private readonly http: HttpClient) {}

  getCredentialsFromAccount(): Observable<Credentials> {
    return this.http.get<Credentials>(`${environment.api.account}/_credentials/algolia`);
  }
}
