import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { NavigationAction, NavigationItem } from '@wizbii/angular-backoffice-ui';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  activeRoute: string | string[];
  env = environment.apiPlatform;
  get icons(): string[] {
    return [...this.items].map((i) => i.icon);
  }

  items = [];
  allItems = [
    {
      id: 'membres',
      route: `/${FeaturesRoutingEnum.Members}`,
      label: 'Membres',
      icon: 'members',
      isImplemented: true,
    },
    {
      id: 'entreprises',
      route: `/${FeaturesRoutingEnum.Firms}`,
      label: 'Entreprises',
      icon: 'firms',
      isImplemented: true,
    },
    {
      id: 'recruteurs',
      route: `/${FeaturesRoutingEnum.Recruteurs}`,
      label: 'Recruteurs',
      icon: 'recruteurs',
      isImplemented: true,
    },
    {
      id: 'offres',
      route: `/${FeaturesRoutingEnum.Offers}`,
      label: "Offres d'emploi",
      icon: 'offers',
      isImplemented: true,
    },
    {
      id: 'emails',
      route: `/${FeaturesRoutingEnum.Emails}`,
      label: 'E-mails',
      icon: 'mails',
      isImplemented: true,
    },
  ];

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.items = this.allItems.filter((it) => {
      return it.isImplemented || false;
    });

    this.activeRoute = `/${this.router.url.split('/')[1]}`;
  }

  handleItemClicked(item: NavigationItem | NavigationAction): void {
    this.router.navigate([item['route']]);
  }

  trackByIcon(_: number, icon: string): string {
    return icon;
  }
}
