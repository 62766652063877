import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params } from '@angular/router';
import { environment } from '@environment';
import { Store } from '@ngxs/store';
import { JwtState } from '@stores/jwt/jwt.state';
import { buildUrl } from '@wizbii/angular-utilities';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedGuard implements CanActivate {
  constructor(readonly store: Store) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const isLogged$: Observable<boolean> = this.store.select(JwtState.isLogged);
    const hasLoginToken$: Observable<boolean> = of(route.queryParamMap.has('login-token'));

    return combineLatest([isLogged$, hasLoginToken$]).pipe(
      map(([isLogged, hasLoginToken]) => {
        if (!isLogged && !hasLoginToken) {
          const params: Params = {
            redirect_url: window.location.origin,
            appId: environment.applicationId,
          };
          window.location.href = buildUrl(environment.api.sso, params);

          return false;
        }
        return true;
      })
    );
  }
}
