import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { JwtSet } from '@stores/jwt/jwt.action';
import { JwtState } from '@stores/jwt/jwt.state';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginTokenGuard implements CanActivate {
  constructor(readonly store: Store, readonly authenticationWebservice: AuthenticationWebservice) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const isLogged$: Observable<boolean> = this.store.select(JwtState.isLogged);
    const loginToken$: Observable<string> = of(route.queryParamMap.get('login-token'));

    return combineLatest([isLogged$, loginToken$]).pipe(
      switchMap(([isLogged, loginToken]) => {
        if (isLogged) {
          return of(true);
        }

        if (!loginToken) {
          return of(false);
        }

        return this.authenticationWebservice.getJwtFromLoginToken(loginToken).pipe(
          tap((tokens) => {
            this.store.dispatch(new JwtSet(tokens));
          }),
          map(() => true),
          catchError(() => {
            return of(false);
          })
        );
      })
    );
  }
}
