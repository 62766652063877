<div class="page">
  <div class="page__content">
    <div>
      <p class="page__content__title">404</p>
      <p class="page__content__subTitle">Oooopss...</p>
      <p class="page__content__description">La page que vous recherchez est introuvable !</p>
      <button class="btn-clean" (click)="goBack()">Retour</button>
    </div>

    <img src="/assets/illustration-page-error.svg" alt="" class="page__content__illustration" />
  </div>
</div>
