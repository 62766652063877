import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { TransferState } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { RECRUITER_API_CONFIG, RecruiterWebservice } from '@ats/webservices';
import { environment } from '@environment';
import { PageNotFoundComponent } from '@features/errors-pages/page-not-found/page-not-found.component';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { LoginTokenGuard } from '@features/guards/login-token/login-token.guard';
import { NotLoggedGuard } from '@features/guards/not-logged/not-logged.guard';
import { LayoutComponent } from '@features/layouts/layout/layout.component';
import {
  DrivingSchoolWebservice,
  PERMII_API_CONFIG,
  ProfileWebservice as DriveProfileWebservice,
} from '@wizbii-drive/webservices';
import { ALGOLIA_SERVICE_TOKEN } from '@wizbii/algolia';
import { BoNavigationSidebarMenuModule, MustBeAuthorizedGuard } from '@wizbii/angular-backoffice-ui';
import { SvgIconModule, WzbButtonModule } from '@wizbii/angular-ui';
import {
  ACCOUNT_API_CONFIG,
  AccountWebservice,
  AlgoliaWebservice,
  AUTHENTICATION_API_CONFIG,
  AuthenticationWebservice,
  CityWebservice,
  COMPANY_API_CONFIG,
  ContractWebservice,
  DegreeWebservice,
  EMAIL_API_CONFIG,
  EmailWebservice,
  JOB_API_CONFIG,
  JobWebservice,
  PLACE_API_CONFIG,
  PROFILE_API_CONFIG,
  ProfileWebservice,
  SCHOOL_API_CONFIG,
  SUBSCRIPTION_API_CONFIG,
  SubscriptionWebservice,
  WizCompanyWebservice,
} from '@wizbii/webservices';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        canActivate: [LoginTokenGuard, NotLoggedGuard, MustBeAuthorizedGuard],
        component: LayoutComponent,
        children: [
          { path: '', redirectTo: FeaturesRoutingEnum.Members, pathMatch: 'full' },
          {
            path: FeaturesRoutingEnum.Dashboard,
            loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
          },
          {
            path: FeaturesRoutingEnum.Members,
            loadChildren: () => import('./members/members.module').then((m) => m.MembersModule),
          },
          {
            path: FeaturesRoutingEnum.Firms,
            loadChildren: () => import('./firms/firms.module').then((m) => m.FirmsModule),
          },
          {
            path: FeaturesRoutingEnum.OldRecruiters,
            loadChildren: () => import('./old-recruiters/oldRecruiters.module').then((m) => m.OldRecruitersModule),
          },
          {
            path: FeaturesRoutingEnum.Recruteurs,
            loadChildren: () => import('./recruiters/recruiters.module').then((m) => m.RecruitersModule),
          },
          {
            path: FeaturesRoutingEnum.Emails,
            loadChildren: () => import('./emails/email.module').then((m) => m.EmailModule),
          },
          {
            path: FeaturesRoutingEnum.Offers,
            loadChildren: () => import('./jobs/jobs.module').then((m) => m.JobsModule),
          },
        ],
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ]),
    LayoutModule,
    SvgIconModule,
    BoNavigationSidebarMenuModule,
    WzbButtonModule,
    MatNativeDateModule,
  ],
  providers: [
    AlgoliaWebservice,
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
      },
    },
    TransferState,
    {
      provide: ALGOLIA_SERVICE_TOKEN,
      useValue: {
        applicationId: environment.algolia.applicationId,
        apiKey: environment.algolia.apiKey,
        options: {},
      },
    },
    AuthenticationWebservice,
    {
      provide: PLACE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.place,
      },
    },
    CityWebservice,

    {
      provide: PERMII_API_CONFIG,
      useValue: {
        baseUrl: environment.api.permii,
      },
    },
    DriveProfileWebservice,
    AccountWebservice,
    {
      provide: ACCOUNT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.account,
        baseUrlHost: environment.host.account,
        locale: environment.locale,
      },
    },
    SubscriptionWebservice,
    {
      provide: SUBSCRIPTION_API_CONFIG,
      useValue: {
        baseUrl: environment.api.subscription,
      },
    },
    ProfileWebservice,
    {
      provide: PROFILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.profile,
      },
    },
    EmailWebservice,
    {
      provide: EMAIL_API_CONFIG,
      useValue: {
        baseUrl: environment.api.email,
      },
    },
    WizCompanyWebservice,
    {
      provide: COMPANY_API_CONFIG,
      useValue: {
        baseUrl: environment.api.company,
      },
    },
    RecruiterWebservice,
    {
      provide: RECRUITER_API_CONFIG,
      useValue: {
        baseUrl: environment.api.recruiter,
      },
    },
    JobWebservice,
    ContractWebservice,
    {
      provide: JOB_API_CONFIG,
      useValue: {
        baseUrl: environment.api.job,
      },
    },
    DegreeWebservice,
    {
      provide: SCHOOL_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.school,
      },
    },
    DrivingSchoolWebservice,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [LayoutComponent],
  exports: [],
})
export class FeaturesModule {}
