<mat-progress-bar *ngIf="loadingBarService.value$ | async" mode="indeterminate" class="progess-bar"></mat-progress-bar>

<main #container class="wrapper">
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</main>

<app-sprite></app-sprite>

<wzb-notifications></wzb-notifications>
