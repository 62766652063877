export enum FeaturesRoutingEnum {
  Dashboard = 'dashboard',
  Members = 'membres',
  Firms = 'entreprises',
  Recruteurs = 'recruteurs',
  OldRecruiters = 'old-recruiters',
  Offers = 'offres',
  Emails = 'emails',
  SignIn = 'sign-in',
  LogOut = 'deconnection',
}
