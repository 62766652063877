<main class="container">
  <wzb-bo-navigation-sidebar-menu
    [items]="items"
    [activeRoute]="activeRoute"
    [reduced]="true"
    [reducible]="false"
    (itemClicked)="handleItemClicked($event); activeRoute = $event.route"
    (actionClicked)="handleItemClicked($event)"
  >
    <a menuLogo routerLink="/">
      <svg width="69" height="16" viewBox="0 0 69 16" fill="none">
        <path
          d="M10.0731 12.552H4.74509L3.7551 15H0.119095L5.6811 2.4H9.19109L14.7711 15H11.0631L10.0731 12.552ZM9.0291 9.924L7.4091 5.892L5.7891 9.924H9.0291Z"
          fill="white"
        />
        <path
          d="M25.8996 1.644V15H22.6416V14.028C22.0056 14.784 21.0516 15.162 19.7796 15.162C18.8916 15.162 18.0816 14.958 17.3496 14.55C16.6176 14.13 16.0356 13.536 15.6036 12.768C15.1836 12 14.9736 11.112 14.9736 10.104C14.9736 9.096 15.1836 8.214 15.6036 7.458C16.0356 6.69 16.6176 6.102 17.3496 5.694C18.0816 5.286 18.8916 5.082 19.7796 5.082C20.9436 5.082 21.8436 5.418 22.4796 6.09V1.644H25.8996ZM20.4996 12.444C21.0876 12.444 21.5736 12.24 21.9576 11.832C22.3416 11.412 22.5336 10.836 22.5336 10.104C22.5336 9.384 22.3416 8.82 21.9576 8.412C21.5856 8.004 21.0996 7.8 20.4996 7.8C19.8996 7.8 19.4076 8.004 19.0236 8.412C18.6396 8.82 18.4476 9.384 18.4476 10.104C18.4476 10.836 18.6396 11.412 19.0236 11.832C19.4076 12.24 19.8996 12.444 20.4996 12.444Z"
          fill="white"
        />
        <path
          d="M40.808 5.082C42.008 5.082 42.962 5.442 43.67 6.162C44.39 6.882 44.75 7.968 44.75 9.42V15H41.33V9.978C41.33 9.294 41.198 8.79 40.934 8.466C40.682 8.142 40.322 7.98 39.854 7.98C39.338 7.98 38.924 8.16 38.612 8.52C38.3 8.88 38.144 9.426 38.144 10.158V15H34.724V9.978C34.724 8.646 34.232 7.98 33.248 7.98C32.72 7.98 32.3 8.16 31.988 8.52C31.676 8.88 31.52 9.426 31.52 10.158V15H28.1V5.244H31.358V6.27C31.718 5.874 32.144 5.58 32.636 5.388C33.14 5.184 33.686 5.082 34.274 5.082C34.958 5.082 35.57 5.208 36.11 5.46C36.65 5.712 37.088 6.096 37.424 6.612C37.808 6.12 38.294 5.742 38.882 5.478C39.47 5.214 40.112 5.082 40.808 5.082Z"
          fill="white"
        />
        <path
          d="M46.9085 5.244H50.3285V15H46.9085V5.244ZM48.6185 4.164C47.9945 4.164 47.4905 3.99 47.1065 3.642C46.7225 3.294 46.5305 2.862 46.5305 2.346C46.5305 1.83 46.7225 1.398 47.1065 1.05C47.4905 0.702 47.9945 0.528 48.6185 0.528C49.2425 0.528 49.7465 0.696 50.1305 1.032C50.5145 1.356 50.7065 1.776 50.7065 2.292C50.7065 2.832 50.5145 3.282 50.1305 3.642C49.7465 3.99 49.2425 4.164 48.6185 4.164Z"
          fill="white"
        />
        <path
          d="M52.5511 5.244H55.9711V15H52.5511V5.244ZM54.2611 4.164C53.6371 4.164 53.1331 3.99 52.7491 3.642C52.3651 3.294 52.1731 2.862 52.1731 2.346C52.1731 1.83 52.3651 1.398 52.7491 1.05C53.1331 0.702 53.6371 0.528 54.2611 0.528C54.8851 0.528 55.3891 0.696 55.7731 1.032C56.1571 1.356 56.3491 1.776 56.3491 2.292C56.3491 2.832 56.1571 3.282 55.7731 3.642C55.3891 3.99 54.8851 4.164 54.2611 4.164Z"
          fill="white"
        />
        <path
          d="M64.5477 5.082C65.7597 5.082 66.7317 5.442 67.4637 6.162C68.2077 6.882 68.5797 7.968 68.5797 9.42V15H65.1597V9.978C65.1597 8.646 64.6257 7.98 63.5577 7.98C62.9697 7.98 62.4957 8.172 62.1357 8.556C61.7877 8.94 61.6137 9.516 61.6137 10.284V15H58.1937V5.244H61.4517V6.306C61.8357 5.91 62.2917 5.61 62.8197 5.406C63.3477 5.19 63.9237 5.082 64.5477 5.082Z"
          fill="white"
        />
      </svg>
    </a>

    <ng-container *ngFor="let icon of icons; trackBy: trackByIcon">
      <svg-icon *wzbBoNavigationSidebarMenuIcon="icon" [style.color]="'white'" [icon]="icon" width="1.125em"></svg-icon>
    </ng-container>

    <wiz-sidebar menuOption [environment]="env"></wiz-sidebar>
  </wzb-bo-navigation-sidebar-menu>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</main>
